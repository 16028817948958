import styled from 'styled-components'
import { md, themeColor } from 'config/variables'

export const PrivacypolicysecStyles = styled.div`
  padding-top: 20px;
  margin-bottom: 100px;
  position: relative;
  ${md(`
    margin-bottom: 20px;
    `)}
  .container {
    max-width: 900px;
    p {
      font-size: 18px;
      line-height: 28px;
    }
    h3 {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 10px;
      ${md(`
        font-size: 28px;
        line-height: 34px;
        `)}
    }
    h4 {
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 10px;
      ${md(`
        font-size: 26px;
        line-height: 32px;
        `)}
    }
    > ul {
      > li {
        list-style: none;
        font-size: 18px;
        line-height: 28px;
        padding-bottom: 30px;
        ${md(`
            padding-bottom: 20px;
        `)}
        .pl-20 {
          padding-left: 20px;
          margin-top: 30px;
        }
        ul {
          padding-left: 20px;
          margin-bottom: 30px;
          li {
            list-style: square;
            padding-bottom: 5px;
          }
        }
        a {
          word-break: break-all;
          color: ${themeColor};
        }
      }
    }
  }
`
