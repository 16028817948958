import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import Privacypolicysec from 'components/Privacypolicysec/Privacypolicysec'
import Banner from 'components/Banner/Banner'
import styled from 'styled-components'
import { sm } from 'config/variables'

export const PrivacyPolicyPage = styled.div`
  .banner {
    margin-bottom: 50px;
    ${sm(`
      margin-bottom: 40px;
    `)}
    .banner-content {
      max-width: 763px;
    }
    .banner-img {
      display: none;
    }
  }
`

export const PrivacyPolicyProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Privacy <span className="heading-red">Policy</span>
      </>
    ),
    bannerContent: (
      <>
        We, at Simform, are committed to protecting the privacy of our visitors,
        for we do not spam, sell, rent, lease or give away the information to
        any third party/parties.
        <br />
        <br />
        It is projected to describe how we use and process your personal
        information. Please ensure that by visiting and using simform.com, you
        are agreeing and accepting the policies that are described in this
        Privacy Policy.
        <br />
        <br />
        This Privacy Policy also explains how we are going to use your personal
        information and what happens to any personal data that you provide to
        us. Moreover, we do update the policies of this page from time to time,
        so please keep reviewing the page on a regular basis.
      </>
    ),
  },
}

const PrivacyPage = props => {
  const { location } = props

  return (
    <Layout mainClass="home" location={location}>
      <SEO
        title="Privacy Policy of Simform "
        keywords={[`Simform`]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/privacy-policy/',
          },
          {
            property: 'og:image',
            content: 'https://www.simform.com/banner.png',
          },
        ]}
      />
      <PrivacyPolicyPage>
        <Banner
          bannerData={PrivacyPolicyProps}
          showBannerImage={false}
          {...props}
        />
        <Privacypolicysec />
      </PrivacyPolicyPage>
    </Layout>
  )
}

export default PrivacyPage

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/home-hero-banner@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
