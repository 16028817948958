import React from 'react'

import { PrivacypolicysecStyles } from './Privacypolicysec.styles'
import { Link } from 'gatsby'
import { images } from 'config/images'

function Privacypolicysec() {
  return (
    <PrivacypolicysecStyles>
      <div className="container">
        <ul>
          <li>
            <h3>1. Information We Collect</h3>
            <div className="pl-20">
              <h4>(i) Personal Information</h4>
              <p>
                The personal information that we may collect include name,
                contact information, IP addresses, product and service
                selections and other information, identifying you. We may also
                gather and collect personal information from you at different
                points, including but not limited to the following:
              </p>
              <ul>
                <li>
                  At the time of communicating with you as a customer or
                  prospective customer
                </li>
                <li>
                  When you prefer to visit our website or make use of the
                  platform
                </li>
                <li>
                  While registering you as an end-user of our products or
                  services and one account is especially made for you
                </li>
                <li>
                  When you fill our “Contact Us” “Let’s talk” forms to get in
                  touch with our experts
                </li>
              </ul>
              <h4>(ii) Non-personal Data</h4>
              <p>
                We may also collect or gather non-personal data or information
                about users whenever they prefer to interact with our website.
                Non-personal data and information include the browser name that
                user access, the type of computer, technical information like
                operating system and the Internet service providers and other
                similar information via Google Analytics.
              </p>
            </div>
          </li>
          <li>
            <h3>2. Cookies</h3>
            <p>
              Our website does not collect any information about users, except
              for the information contained in Cookies. Talking about cookies,
              they are small text files, which are mainly saved and retrieved
              information about your visit to our website. We use cookies to
              give personalized experience on our website. Here, you can find
              how we use cookies:
            </p>
            <ul>
              <li>
                <strong>Session cookies:</strong> generally, session cookies are
                cached mainly for the duration of your use of our website
              </li>
              <li>
                <strong>Permanent cookies:</strong> permanent cookies are stored
                in your terminal device for the time specified in the Cookie
                files parameters or until you delete.
              </li>
            </ul>
            <p>
              You can adjust the settings on your computer in order to decline
              any cookies if you wish to.
            </p>
          </li>
          <li>
            <h3>3. Disclosing Your Information</h3>
            <p>
              We assure you that we will not disclose your gathered personal
              information to any other party than in agreement with this Privacy
              Policy. Some of the circumstances given below when we may need to
              share your share:
            </p>
            <ul>
              <li>
                In any situation, when we might have to sell any or all of our
                business to the buyer.
              </li>
              <li>
                We may disclose your personal information when it is legally
                required by the law.
              </li>
              <li>To protect fraud and reduce the risk of fraud.</li>
            </ul>
            <p>
              Simform never sell, trade or rent users’ personal information to
              others. If required, we may share general demographic data and
              information that is not linked to any personal information
              regarding visitors with our trusted business partners, affiliates
              and advertisers for the purpose mentioned above.
            </p>
          </li>
          <li>
            <h3>4. Security</h3>
            <p>
              Here, we make sure that your shared personal or non-personal
              information is safe and secure from any unauthorized access, use,
              loss or destruction. Personal or non-personal information provided
              by you is stored on a safe and secure web server that is managed
              by Simform. To protect from authorized access, we have used
              physical, electronic, and managerial procedures to safeguard and
              secure the information.
            </p>
          </li>
          <li>
            <h3>5. Third Party Links/Websites</h3>
            <p>
              Simform may link to third-party websites and services when
              required; however, we are not responsible for the privacy
              statements, practices or the contents of such third-party sites.
            </p>
          </li>
          <li>
            <h3>6. Changes to Privacy Policy</h3>
            <p>
              Simform reserves the right to change, edit or amend this Privacy
              Policy at any time without any prior notice. When we change, edit
              or remove any part from this privacy policy, we will mention the
              updated date at the bottom of this privacy policy page. We
              encourage our users to keep reviewing this page frequently in
              order to stay updated with the changes that we perform. The
              continuing use of our website means that you agree and accept
              those changes. Read our Privacy Policy in detail{' '}
              <a href={images.privacyPolicy} target="_blank">
                here.
              </a>
            </p>
          </li>
          <li>
            <h3>7. Contact Us</h3>
            <p>
              In case, if you have any query or question regarding our Privacy
              Policy, you can get in touch with us at:{' '}
              <a href="mailto:contactus@simform.com">contactus@simform.com</a>
            </p>
          </li>
        </ul>
      </div>
    </PrivacypolicysecStyles>
  )
}

export default Privacypolicysec
